<template>
    <IFrame v-if="vSrc" ref="iFrameRef" :src="vSrc" :enabled="enabled"
        :syncContexts="syncContexts" @load="handleLoad" />
</template>
<script setup lang="ts">
import { ref, watch } from 'vue';
import IFrame from 'o365.vue.components.Iframe.vue';

const props = withDefaults(defineProps<{
    src?: string
    enabled?: boolean,
    syncContexts?: boolean,
}>(), {
    enabled: undefined,
    syncContexts: undefined,
});

const emit = defineEmits<{
    (e: 'load', event: Event, currentSrc: string|null): void
}>();

let vSrc: string | undefined = undefined;
const iFrameRef = ref<IFrame | null>(null);

function handleLoad(e: Event, src: string|null) {
    emit('load', e, src);
}

let validSrcSet = false;
function validateAndSetSrc(pSrc?: string) {
    if (pSrc != null && !pSrc.includes('undefined')) {
        if (validSrcSet) { return; }
        vSrc = pSrc;
        validSrcSet = true;
    } else if (validSrcSet) {
        vSrc = undefined;
        validSrcSet = false;
    }
}
validateAndSetSrc(props.src);

const post = (...args) => {
    iFrameRef?.value?.post(...args);
}

watch(() => props.src, (newVal, oldVal) => {
    if (iFrameRef.value && validSrcSet && iFrameRef.value.iframeRef && iFrameRef.value.iframeRef.src && iFrameRef.value.iframeRef.contentWindow) {
        try {
            iFrameRef.value.iframeRef.contentWindow.history.pushState(oldVal, '', newVal);
        } catch (ex) {
            console.warn(ex);
        }
    }
    validateAndSetSrc(newVal);
});

defineExpose({ iFrameRef, post });
</script>